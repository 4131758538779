<template>
  <NewProductItem
    v-if="item.deliveryInfo?.trend_word_id"
    v-tap="getAnalysis('tap')"
    v-expose="getAnalysis('expose')"
    :style="compStyle"
    class="trend-list__product-card"
    :class="{ 'trend-list__product-card-right': constantData?.GB_cssRight }"
    :language="language"
    :constant-data="constantData"
    :item="item"
    :index="index"
    :column="2"
    :config="itemConfig"
    @clickItem="clickItem(item)"
    @mounted="productItemMounted"
  >
    <template #cardBottom>
      <TrendsLabel
        :item="item"
        :language="language"
        :constant-data="constantData"
      />
    </template>
  </NewProductItem>
</template>
<script>
import { defineComponent, computed } from 'vue'
import { useRouter } from 'public/src/pages/common/RiskCommon/utils.js'
import NewProductItem from 'public/src/pages/components/product/item_v3/MultipleImageRowCard.vue'
import TrendsLabel from './TrendsLabel.vue'
import { stringifyQueryString } from '@shein/common-function'
export default defineComponent({
  name: 'TrendListContent',  // 列表趋势内容体样式和时尚店铺内容体基本一致，区分与趋势频道的趋势内容体
  components: {
    NewProductItem,
    TrendsLabel,
  },
  props: {
    language: {
      type: Object,
      default: (() => {})
    },
    item: {
      type: Object,
      default: (() => {})
    },
    index: {
      type: Number,
      default: 0
    },
    config: {
      type: Object,
      default: (() => {}) 
    },
    constantData: {
      type: Object,
      default: (() => {})
    },
    /**
     * 埋点上报字段--基于指令式埋点，wiki:910812171
     * exposeId 曝光埋点id
     * clickId 点击埋点id
     * data: 埋点上报的数据
     */
    analysisData: {
      type: Object,
      default: (() => {})
    },
    // 商卡样式覆盖
    compStyle: {
      type: Object,
      default: (() => {})
    },
  },
  setup (props) {
    const router = useRouter()
    const itemConfig = computed(() => {
      // 时尚店铺内容体商卡配置覆盖
      const specialConfig = {
        ...props.config,
        // 不展示标题
        showTitle: false,
        showTitleTwoLine: false,
        // 不展示加车按钮
        showAddBagBtn: false,
        // 隐藏服务类型标签
        hideServiceLabels: true,
        // 榜单不可点击
        rankingLabelClickable: false,
        // 不展示趋势标签
        showDeliveryWords: false,
        // 不展示负反馈
        showFeedback: false,
        // 不展示历史低价、保留款跟价款标签
        showPriceDiscountDesc: false,
        // 不跳转到商详
        disableMainimgJump: true,
        // 不展示分布式搜索
        showSearchFilters: false,
      }
      return specialConfig
    })

    const getAnalysis = (type) => {
      return {
        ...props.analysisData,
        id: type === 'expose' ? props.analysisData.exposeId : props.analysisData.clickId,
      }
    }
    const productItemMounted = (componetInstance) => {
      componetInstance?.setElAttr?.() // 设置埋点属性在dom上
    }

    const clickItem = async (item) => {
      const { goods_id, cat_id, deliveryInfo } = item
      const { langPath } = props.constantData
      const {
        trend_word_id = '',
        product_select_id = '',
        product_select_url_id = ''
      } = deliveryInfo
      const query = {
        trendId: trend_word_id,
        selectId: product_select_id,
        selectUrlId: product_select_url_id,
        adp: goods_id,
        catId: cat_id,
        src_tab_page_id: getSaPageInfo.tab_page_id,
        src_identifier: `trend=${trend_word_id}`,
        src_module: 'list',
        entry_from: `${window.SaPageInfo?.page_name || ''}\`-\`-\`goods_list`,
      }
      const url = `${langPath}/trend-landing?${stringifyQueryString({
        queryObj: query
      })}`
      router.push(url)
    }

    return {
      itemConfig,
      getAnalysis,
      clickItem,
      productItemMounted,
    }
  },
})
</script>


<style lang="less">
.trend-list__product-card {
  --product-card__bottom-wrapper: 0.16rem 0.2133rem;
  .product-card__selling-proposition-text {
    background-color: transparent !important;
  }
  .product-card__sales-label {
    background-color: transparent !important;
  }
  .product-card__bottom-wrapper {
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/06/21/31/1718954832de72e530b9d9075c97c5afc83299ec80.png');
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
  }

  // 时尚店铺内容体屏蔽 组合购
  .buy-box__container {
    display: none;
  }
  // 时尚店铺内容体屏蔽 一键购
  .one-click-container {
    display: none;
  }
  // 融合隐藏加车按钮
  .product-card__add-btn {
    display: none;
  }
}
.trend-list__slot {
  display: flex;
  align-items: center;
  height: 0.3733rem;
  margin-top: 0.16rem;
}
.trend-list__text {
  margin-left: 4px;
  color: #9462FF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // font-family: "SF Pro";
  font-size: 0.2933rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.trend-list__product-card-right {
  .product-card__bottom-wrapper {
    // ar站点样式
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/06/21/e9/17189549082050116c44d3cbac38662aa02177aedd.png');
  }
}
</style>

