<template>
  <div
    class="delivery-label__wrap"
  >
    <div
      class="delivery-label__content"
    >
      <img 
        v-if="iconLink" 
        class="delivery-label-img"
        :src="iconLink" 
        :style="{width: `${iconWidth/37.5}rem`}"
      />
      <div 
        v-else 
        class="delivery-label-fallback"
      >  
        <span
          
          class="delivery-label-text"
        >
          {{ language.SHEIN_KEY_PWA_26897 }}
        </span>
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' fill='none' viewBox='0 0 5 14'%3E%3Cpath id='Rectangle 34624868' fill='%239462FF' d='M3.424 11.03 5 0H0v14a3.46 3.46 0 0 0 3.424-2.97Z'/%3E%3C/svg%3E"
          class="delivery-label__trapezoid"
          :class="{ 'delivery-label__trapezoid-ar': constantData.GB_cssRight }"
        />
      </div>

      <div class="delivery-label-title">
        <template v-if="isModStoreTag">
          <sui_icon_me_shop_15px
            size="12" 
            color="#9462FF"
            :style="{ margin: constantData.GB_cssRight ? '0 0 0 1px' : '0 1px 0 0' }"
            :is-rotate="constantData.GB_cssRight"
          />
          <span
            ref="titleText"
            class="delivery-label-title__text"
          >
            {{ item.storeInfo?.title || '' }}
          </span>
        </template>
        <span
          v-else
          ref="titleText"
          class="delivery-label-title__text"
        >
          #{{ getWordTitle(item) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { sui_icon_me_shop_15px } from '@shein-aidc/icon-vue2'
import ICON_MAP from './config.js'

export default {
  name: 'TrendsLabel',
  components: {
    sui_icon_me_shop_15px,
  },
  props: {
    constantData: {
      type: Object,
      default: (() => {})
    },
    item: {
      type: Object,
      default: (() => {})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    isModStoreTag: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    iconLink() {
      // 兜底 
      const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
      return ICON_MAP[this.constantData.appLanguage ?? backupKey]?.imgUrl || ''
    },
    iconWidth() {
      // 兜底 
      const backupKey = typeof window !== 'undefined' ? gbCommonInfo?.appLanguage : ''
      return ICON_MAP[this.constantData.appLanguage ?? backupKey]?.imgWidth || ''
    },
  },
  methods: {
    getWordTitle(item) {
      // 剔除趋势词开头的#号
      if (item.bffGoodsInfo && item.deliveryInfo?.display_title) {
        return item.deliveryInfo.display_title.replace(/^#/, '')
      } else {
        return item.deliveryInfo?.display_title || ''
      }
    }
  }
}
</script>

<style lang="less">
.delivery-label__wrap {
  position: relative;
  width: 100%;
  height: 0.3733rem;
  margin-top: 0.16rem;
}
.delivery-label__content {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  width: fit-content;
  color: #9462ff;
  background-color: rgba(217, 199, 255, 0.30);
  border-radius: 2px;
  // 隐藏兜底背景色溢出
  overflow: hidden;
  font-size: 0.2933rem;
  height: .3733rem;

  .delivery-label-title {
    padding: 0 2px;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
    &__text {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      unicode-bidi: plaintext;
      height: .3733rem;
      line-height: 0.4rem; // 解决文案标签未对齐问题
      overflow: hidden;
      width: 100%;
    }
  }
  .delivery-label-fallback {
    position: relative;
    display: flex;
    align-items: center;
    height: .3733rem;
    line-height: .3733rem;
    font-size: .2933rem;
    font-weight: 600;
    color: #fff;
    background-color: #9462ff;
    margin-right: .1333rem;
    
    .delivery-label-text {
      padding: 1px 0 1px 3px;
      height: 100%;
    }
  }
  .delivery-label-img {
    height: 100%;
    width: auto;
    flex-basis: 0;
  }
  .delivery-label__trapezoid {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(90%);
    font-size: 0;
    width: fit-content;
  }
  .delivery-label__trapezoid-ar {
    transform: translateX(90%) rotateY(180deg);
  }
}
</style>
